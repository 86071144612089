import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image/Image'
import Typography from '../Typography/Typography'
import LinkButton from '../LinkButton/LinkButton'
import bannerStyles from './Banner.styles'
import { home } from '../../constants/page-source'
import twStyles from './Banner.tw-styles'

const Banner = ({
  title,
  text,
  contentCollection,
  callToActionCollection,
  image,
  pageSource,
  variant,
  handler,
}) => {
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle.default
  return (
    <div className={style.mainWrapper}>
      <div className={style.gradient} />
      <div className={style.image}>
        <Image priority className={style.imageName} {...image} />
      </div>
      <div className={style.bannerText}>
        <span className={style.badge}>
          <Typography markdown={title} />
        </span>
        <div className={style.textContainer}>
          {contentCollection && contentCollection.items && (
            <div>
              <div className={style.bannerTxtWrapper}>
                {text}
              </div>
            </div>
          )}
        </div>
        <div className={style.linkButton}>
          {callToActionCollection?.items?.map((cta) => (
            <LinkButton pageSource={home} key={cta.sys && cta.sys.id} {...cta} handler={handler} />
          ))}
        </div>
      </div>
      <style jsx global>
        {bannerStyles}
      </style>
    </div>
  )
}

Banner.propTypes = {
  callToActionCollection: PropTypes.instanceOf(Object).isRequired,
  contentCollection: PropTypes.instanceOf(Object),
  image: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  pageSource: PropTypes.string,
  variant: PropTypes.string,
  handler: PropTypes.func,
}

Banner.defaultProps = {
  image: null,
  title: null,
  contentCollection: '',
  text: null,
  link: null,
  pageSource: '',
  variant: '',
  handler: () => {},
}

export default Banner
