import { home, coupons } from '../../constants/page-source'

module.exports = {
  [home]: {
    default: {},
    Teal: {},
    Magenta: {},
    'Dark Blue': {},
    bannerHorizontal: {
      mainWrapper: 'image home-banner-image bg-bgcolor lg:flex items-center container mx-auto relative overflowhidden',
      gradient: '',
      image: 'image w-full relative carousel-im lg:overflow-hidden brand-sec min-h-155 lg:min-h-400 xl:min-h-550 md:absolute md:top-0',
      imageName: 'w-full lg:w-auto imageInnerClass  md:w-auto md:min-w-1260 xl:min-w-1440 xxl:w-full',
      bannerText: 'w-full mt-20px md:mt-0 mx-auto lg:mx-0 md:w-6/12 lg:w-4/12 xl:w-4/12 bg-white px-20 md:px-30 xxl:px-50 py-30 md:p-40 md:relative md:right-0 md:left-0 md:top-15 lg:top-20 md:mb-25 lg:my-15 xl:my-35 xl:top-auto lg:left-70 xl:right-auto xl:left-170 lg:z-10 items-center justify-center',
      text: 'text ',
      badge: 'text-center font-montserratSemiBold font-bold text-24 xl:text-32 leading-widest xl:leading-leading42',
      textContainer: 'flex flex-wrap w-full pb-25',
      arrowIcon: 'inline home-banner-arrow pl-05',
      arrowIconSVG: 'w-14 h-10 inherit inline lg:w-31 lg:h-22',
      bannerTxtWrapper: 'font-montserratlight font-light xl:text-18 text-16 pt-10 md:pt-15 xl:pt-18 leading-lightmedium xl:leading-widest text-center px-05',
      linkButton: 'w-full flex justify-center',
    },
    bannerHorizontalReverse: {
      mainWrapper: 'image home-banner-image bg-bgcolor lg:flex items-center container mx-auto relative overflowhidden',
      gradient: '',
      image: 'image w-full relative carousel-im min-h-155 lg:min-h-400 xl:min-h-550 lg:absolute md:top-0',
      imageName: 'w-full md:w-auto md:min-w-1260 xl:min-w-1440 xxl:w-full',
      bannerText: 'w-full mx-auto lg:w-5/12 xl:w-4/12 bg-white px-20 md:px-30 py-30 md:p-40 md:z-10 xl:p-60 lg:relative md:left-0 md:right-0 md:m-0 lg:my-15 xl:my-35 xxl:my-70 xl:right-auto lg:left-70 xl:left-170 md:top-0 ',
      text: 'text ',
      badge: 'text-center font-montserratSemiBold font-semibold text-24 xl:text-32 leading-widest xl:leading-leading42',
      textContainer: 'flex flex-wrap w-full pb-25',
      arrowIcon: 'inline home-banner-arrow pl-05',
      arrowIconSVG: 'w-14 h-10 inherit inline lg:w-31 lg:h-22',
      bannerTxtWrapper: 'font-montserratlight font-light xl:text-18 text-16 pt-10 md:pt-15 xl:pt-18 leading-lightmedium text-center xl:leading-widest px-05 md:px-15 lg:px-05',
      linkButton: 'w-full flex justify-center',
    },
  },
  default: {
    default: {},
    Blue: {},
    Teal: {},
    Red: {},
    Green: {},
    Magenta: {},
    'Dark Blue': {},
    bannerHorizontal: {
      mainWrapper: 'image home-banner-image bg-bgcolor container mx-auto relative overflow-hidden',
      gradient: '',
      image: 'image w-full relative carousel-im overflow-hidden lg:h-400',
      imageName: 'w-full lg:w-auto lg:h-400',
      bannerText: 'w-full mx-auto md:w-4/12 bg-white p-20 md:p-40 xl:p-60 md:absolute bottom-15 md:bottom-45 lg:bottom-50 right-50 lg:right-170',
      text: 'text ',
      badge: 'text-center font-montserratSemiBold font-semibold text-18 xl:text-24 leading-wider lg:leading-leading29',
      textContainer: 'flex flex-wrap w-full pb-20',
      arrowIcon: 'inline home-banner-arrow pl-05',
      arrowIconSVG: 'w-14 h-10 inherit inline lg:w-31 lg:h-22',
      bannerTxtWrapper: 'font-montserratlight font-light xl:text-18 text-16 pt-05 md:pt-05 xl:pt-08 leading-cardline xl:leading-lightmedium text-center px-05',
    },
  },
  [coupons]: {

    default: {
      mainWrapper: 'image home-banner-image bg-bgcolor container mx-auto relative overflow-hidden',
      gradient: '',
      image: 'image w-full relative carousel-im ',
      imageName: 'w-full ',
      bannerText: 'w-full text-white mx-auto pt-50 md:pl-50 xl:pl-60 xxl:pl-85 px-10 md:pr-0 xl:pt-40 xxl:pt-45 xxl:pr-65 md:text-left absolute top-0 left-0 flex flex-wrap justify-center md:justify-start',
      text: 'text ',
      badge: 'text-center md:text-left xl:w-6/12 xxl:w-5/12 font-montserratBold font-bold text-24 xl:text-30 leading-leading29 lg:leading-wider',
      textContainer: 'w-full pt-05 pb-20 md:pb-35 xl:pb-30 xxl:pb-35 text-14 xl:text-16 font-montserratSemiBold font-semibold leading-light xl:leading-lightmedium',
      arrowIcon: 'inline home-banner-arrow pl-05',
      arrowIconSVG: 'w-14 h-10 inherit inline lg:w-31 lg:h-22',
      bannerTxtWrapper: 'w-11/12 md:w-7/12 xl:w-6/12 xxl:w-5/12 mx-auto md:ml-0 md:pr-20 xl:pr-0 font-montserratSemiBold font-semibold text-center md:text-left xl:text-16 text-14 md:pt-05 xl:pt-08 leading-light xl:leading-lightmedium text-center',
    },
  },
}
