import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Slider from 'react-slick'
import Tile from '../Card/Tile/Tile'
import CarouselStyles from './Carousal.styles'
import Banner from '../Banner/Banner'
import settings from './Carousal.settings'
import twStyles from './Carousal.tw-styles'
import Image from '../Image/Image'
import SelectCauseCarousal from '../SelectCause/SelectCauseCarousal'
import Callout from '../Callout/GenericCallout/Callout'
import LoginStore from '../UserManagement/Login-store'
import { smartPhoneWidth, tabletWidth } from '../../constants/screenWidth'
import NextArrow from './NextArrow'
import PrevArrow from './PreviousArrow'
import Icon from '../Icon/Icon'
import SweepstakesCallout from '../Callout/SweepstakesCallout/SweepstakesCallout'

const Carousal = ({
  carouselPanelsCollection,
  styles,
  galleryContentFlag,
  galleryFlag,
  updateCarouselIndex,
  currentIndex,
  handleSelect,
  selectedCause,
  lazyLoad,
  launchPoint,
  pageSource,
  variant,
  contextualLoginText,
  textIconName,
  siteSettings,
}) => {
  let CarSettings = settings[styles] || settings.default
  if (launchPoint === 'earnPoints') {
    CarSettings = {
      className: 'feature-earnPoints',
      dots: true,
      infinite: true,
      speed: 300,
      centerPadding: '0',
      slidesToShow: 2.12,
      slidesToScroll: 1.01,
      centerMode: true,
      variableWidth: true,
      nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
      prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
      responsive: [
        {
          breakpoint: tabletWidth,
          settings: {
            centerPadding: '1%',
            slidesToShow: LoginStore.featuredCardLength >= 3 ? 2.9 : LoginStore.featuredCardLength,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
          },
        },
        {
          breakpoint: smartPhoneWidth,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1,
            variableWidth: true,
            arrow: false,
            dots: true,
          },
        },
      ],
    }
  }
  const wrapperStyle = twStyles[styles] || twStyles.default
  useEffect(() => {
    const allelems = document.querySelectorAll('.slick-slide, .removetabindex')
    allelems.forEach((allelem) => {
      allelem.removeAttribute('tabindex')
      allelem.removeAttribute('aria-hidden')
    })
  })
  const [carousel, setCarouselRef] = useState()
  useEffect(() => {
    if (currentIndex >= 0 && carousel) {
      carousel.slickGoTo(currentIndex)
    }
  })
  return (
    <>
      <Slider
        ref={(ref) => setCarouselRef(ref)}
        beforeChange={(current, next) => {
          updateCarouselIndex(next)
        }}
        {...CarSettings}
        className={wrapperStyle}
      >
        {carouselPanelsCollection
          && carouselPanelsCollection.items.map((item, index) => {
            switch (item && item.__typename) {
              case 'Tile':
                return (
                  <div key={item.name} className='removetabindex' tabIndex='0'>
                    {galleryContentFlag ? (
                      <Tile
                        surveyName={item.surveyName}
                        heading={item.heading}
                        text={item.text}
                        key={item && item.sys && item.sys.id}
                        launchPoint={launchPoint}
                        pageSource={pageSource}
                        behavior={item.behavior}
                        variant={variant || item.variant}
                        featuredSurvey={siteSettings && siteSettings.featuredSurvey}
                        monthlyPromoRewardId={siteSettings && siteSettings.monthlyPromoRewardId}
                        takeFeedbackSurveyToGetPoints={siteSettings
                          && siteSettings.takeFeedbackSurveyToGetPoints}
                        contextualLoginText={contextualLoginText}
                        textIconName={textIconName || item.textIconName}
                        arrowColorVariant={item && item.arrowColorVariant}
                        subText={item?.subText}
                        mosStartAndEndDate={siteSettings?.mosStartAndEndDate}
                      />
                    )
                      : (
                        <Tile
                          {...item}
                          surveyName={item.surveyName}
                          heading={galleryFlag ? '' : item.heading}
                          text={galleryFlag ? '' : item.text}
                          key={item && item.sys && item.sys.id}
                          launchPoint={launchPoint}
                          pageSource={pageSource}
                          behavior={item.behavior}
                          variant={variant || item.variant}
                          featuredSurvey={siteSettings && siteSettings.featuredSurvey}
                          monthlyPromoRewardId={siteSettings && siteSettings.monthlyPromoRewardId}
                          takeFeedbackSurveyToGetPoints={siteSettings
                            && siteSettings.takeFeedbackSurveyToGetPoints}
                          contextualLoginText={contextualLoginText}
                          textIconName={textIconName || item.textIconName}
                          arrowColorVariant={item && item.arrowColorVariant}
                          cardPointsLabelInDashboardPage={item?.tileLabel}
                          subText={item?.subText}
                          mosStartAndEndDate={siteSettings?.mosStartAndEndDate}
                        />
                      )}
                  </div>
                )
              case 'ImageSet':
                return <Image {...item} key={item && item.sys && item.sys.id} lazyLoad={lazyLoad} />
              case 'Brand':
                return (
                  <div key={item.name} className='removetabindex' tabIndex='0'>
                    {item.logo && (
                      <Tile
                        key={item && item.sys && item.sys.id}
                        text={item.name}
                        image={item.logo && { image: { url: item.logo.url } }}
                        link={item.url}
                        pageSource={pageSource}
                        variant='Brand Carousel'
                      />
                    )}
                  </div>
                )
              case 'Banner':
                return (
                  <Banner
                    {...item}
                    key={item && item.sys && item.sys.id}
                    pageSource={pageSource}
                  />
                )
              case 'Carousel':
                return (
                  <SelectCauseCarousal
                    {...item}
                    isActive={selectedCause === item.destinationId}
                    handleSelect={handleSelect}
                    key={item && item.sys && item.sys.id}
                    variant={variant}
                    pageSource={pageSource}
                  />
                )
              case 'Callout':
                if (pageSource === 'rewardsDashboard' && variant === 'feature-earn-points') {
                  return (
                    <SweepstakesCallout
                      {...item}
                      pageSource='rewardsDashboard'
                      variant='sweepsstakes'
                      behavior={item.behavior}
                    />
                  )
                }
                return (
                  <Callout
                    pageSource={pageSource}
                    {...item}
                    key={item && item.sys && item.sys.id}
                    index={index}
                  />
                )

              default:
                break
            }
            return null
          })}
      </Slider>
      <style jsx global>
        {CarouselStyles}
      </style>
    </>
  )
}

Carousal.propTypes = {
  carouselPanelsCollection: PropTypes.instanceOf(Object).isRequired,
  styles: PropTypes.string,
  galleryContentFlag: PropTypes.bool,
  galleryFlag: PropTypes.bool,
  updateCarouselIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  lazyLoad: PropTypes.string,
  handleSelect: PropTypes.func,
  selectedCause: PropTypes.string,
  launchPoint: PropTypes.string,
  panelStyle: PropTypes.string,
  pageSource: PropTypes.string,
  variant: PropTypes.string,
  surveyName: PropTypes.oneOfType([PropTypes.object]),
  siteSettings: PropTypes.oneOfType([PropTypes.object]),
  confirmationModalLabels: PropTypes.oneOfType([PropTypes.object]),
  contextualLoginText: PropTypes.string,
  textIconName: PropTypes.string,
  setShowOnboardingModal: PropTypes.func,
}

Carousal.defaultProps = {
  styles: null,
  updateCarouselIndex: () => {},
  currentIndex: -1,
  lazyLoad: 'true',
  galleryContentFlag: false,
  galleryFlag: false,
  selectedCause: '',
  handleSelect: () => {},
  launchPoint: '',
  panelStyle: null,
  pageSource: '',
  variant: null,
  surveyName: {},
  siteSettings: null,
  contextualLoginText: null,
  confirmationModalLabels: null,
  textIconName: null,
  setShowOnboardingModal: () => {},
}

export default observer(Carousal)
