import React from 'react'
import NextArrow from './NextArrow'
import PrevArrow from './PreviousArrow'
import Icon from '../Icon/Icon'
import {
  smartPhoneWidth, tabletWidth, tabletProWidth, desktopProWidth,
} from '../../constants/screenWidth'

export default {
  default: {
    dots: false,
    infinite: false,
    slidesToShow: 4.6,
    slidesToScroll: 4,
    arrow: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 100,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  },
  'good-news-home': {
    dots: false,
    infinite: false,
    slidesToShow: 4.6,
    slidesToScroll: 3,
    arrow: false,
    transformEnabled: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 3,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  },
  brand: {
    dots: false,
    rows: 2,
    infinite: false,
    slidesToShow: 4.9,
    slidesToScroll: 5,
    arrow: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: tabletProWidth,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 100,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  },
  cause1: {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '1%',
    arrow: false,
    transformEnabled: false,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          transformEnabled: false,
          variableWidth: true,
          centerPadding: '10%',
        },
      },
    ],
  },
  'cause-about': {
    dots: false,
    infinite: false,
    arrow: true,
    slidesToShow: 1,
    lazyLoad: false,
    nextArrow: <NextArrow><Icon name='arrowRightWhite' /></NextArrow>,
    prevArrow: <NextArrow><Icon name='arrowLeftWhite' /></NextArrow>,
  },
  'cause-help': {
    dots: true,
    arrow: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
    nextArrow: <NextArrow name='arrowRightWhite' />,
    prevArrow: <PrevArrow name='arrowLeftWhite' />,
    appendDots: (dots) => (
      <div>
        <ul>
          {' '}
          {dots}
          {' '}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className='homeCarouselDots'>
        {`slide ${i + 1}`}
      </div>
    ),
  },
  'article-text': {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    swipe: false,
    lazyLoad: true,
    adaptiveHeight: true,
    arrow: true,
    nextArrow: <NextArrow name='arrowRightWhite' />,
    prevArrow: <PrevArrow name='arrowLeftWhite' />,
  },
  cause2: {
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    className: 'center common',
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
    ],
  },
  'home-carousal': {
    className: 'center home-banner-carousel',
    centerMargin: '15%',
    lazyload: false,
    centerMode: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    variableWidth: true,
    arrow: false,
    nextArrow: <div><Icon name='arrowRightGray' /></div>,
    prevArrow: <div><Icon name='arrowLeftGray' /></div>,
    responsive: [
      {
        breakpoint: tabletProWidth,
        settings: {
          centerPadding: '0',
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '0',
          slidesToShow: 0.9,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          centerPadding: '0',
          slidesToShow: 0.9,
          variableWidth: true,
        },
      },
    ],
  },
  'good-tracker': {
    className: 'center',
    centerPadding: '23%',
    centerMode: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrow: false,
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <div><Icon name='arrowLeftGray' /></div>,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '14%',
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          centerPadding: '15%',
        },
      },
    ],
  },
  'get-started': {
    className: 'center home-banner-carousel',
    centerMargin: '15%',
    centerMode: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    arrow: false,
    responsive: [
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {

        },
      },
    ],
  },
  'select-cause': {
    className: 'center',
    centerMode: true,
    centerPadding: '6%',
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrow: false,
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    appendDots: (dots) => (
      <div>
        <ul>
          {' '}
          {dots}
          {' '}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className='homeCarouselDots'>
        {`slide ${i + 1}`}
      </div>
    ),
    responsive: [
      {
        breakpoint: tabletProWidth,
        settings: {
          centerPadding: '10%',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '30%',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          centerPadding: '15%',
          slidesToShow: 1,
        },
      },
    ],
  },
  'earn-points-quiz': {
    className: 'feature-earnPoints',
    dots: true,
    infinite: true,
    speed: 300,
    centerPadding: '1%',
    slidesToShow: 2.11,
    centerMode: true,
    variableWidth: true,
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    responsive: [
      {
        breakpoint: tabletProWidth,
        settings: {
          centerPadding: '29%',
          slidesToShow: 2.11,
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '6%',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          variableWidth: true,
          arrow: false,
          dots: true,
        },
      },
    ],
  },
  'rewardsSweeps-carousal': {
    className: 'center',
    centerMode: true,
    centerPadding: '0',
    dots: true,
    infinite: false,
    slidesToShow: 1.001,
    arrows: false,
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    responsive: [
      {
        breakpoint: tabletProWidth,
        settings: {
          centerPadding: '0%',
          slidesToShow: 1.001,
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          centerPadding: '0%',
          slidesToShow: 1.001,
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          centerPadding: '0%',
          slidesToShow: 1.001,
        },
      },
    ],
  },
  'select-sustainability': {
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div className='homeCarouselDots'>
        {`slide ${i + 1}`}
      </div>
    ),
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    slidesToShow: 3,
    centerMode: true,
    className: 'center',
    dots: true,
    arrows: true,
    infinite: true,
    centerPadding: '6%',
    responsive: [
      {
        breakpoint: tabletProWidth,
        settings: {
          slidesToShow: 2,
          centerPadding: '10%',
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '22%',
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '7%',
        },
      },
    ],
  },
  'select-olympics': {
    nextArrow: <NextArrow><Icon name='arrowRightGray' /></NextArrow>,
    prevArrow: <PrevArrow><Icon name='arrowLeftGray' /></PrevArrow>,
    appendDots: (dots) => (
      <div>
        <ul>
          {' '}
          {dots}
          {' '}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className='homeCarouselDots'>
        {`slide ${i + 1}`}
      </div>
    ),
    slidesToShow: 1,
    className: 'center',
    centerMode: true,
    dots: true,
    infinite: true,
    arrows: true,
    centerPadding: '18%',
    responsive: [
      {
        breakpoint: desktopProWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '14.5%',
        },
      },
      {
        breakpoint: tabletProWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '6%',
        },
      },
      {
        breakpoint: tabletWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '6%',
        },
      },
      {
        breakpoint: smartPhoneWidth,
        settings: {
          slidesToShow: 1,
          centerPadding: '0%',
        },
      },
    ],
  },
}
