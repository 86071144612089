import React from 'react'
import PropTypes from 'prop-types'

function NextArrow(props) {
  const { className, onClick, children } = props
  return (
    <button
      type='button'
      className={className}
      aria-label='Previous Slide'
      onClick={onClick}
    >
      {children}
    </button>
  )
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

NextArrow.defaultProps = {
  className: '',
  onClick: () => {},
  children: (
    <img
      src='https://images.ctfassets.net/5tx4oa3foqys/3iyiTggRPAazDq3yG3rrkJ/0bf85fbe9b1ced16871f04d458a664fe/prev.svg'
      alt='prev'
    />
  ),
}

export default NextArrow
