import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { carousalButtonClickEvent } from '../../../helpers/gaEvent.helper'
import LinkButton from '../../LinkButton/LinkButton'
import Image from '../../Image/Image'
import Icon from '../../Icon/Icon'
import Typography from '../../Typography/Typography'
import Button from '../../Button'
import sweepstakeStyles from './SweepstakesCallout.styles'
import { isSurveyCompleted, isSegmantaLoaded, feedbackSurvey } from '../../../helpers/segmanta.helper'
import getSurveyId from '../../../containers/SurveyLanding/Survey.helper'
import twStyles from './SweepstakesCallout.styles.tw'

let surveyID = ''
const SweepstakesCallout = ({
  image,
  title,
  subTitle,
  text,
  iconName,
  link,
  variant,
  pageSource,
  surveyName,
  behaviour,
  offerLabelInFeaturedCard,
  callToActionsCollection,
}) => {
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle.default
  const [surveyCompleted, setSurveyCompleted] = useState(false)

  useEffect(() => {
    if (surveyName && surveyName.surveyName) {
      surveyID = getSurveyId(surveyName.surveyName)
    }
    if (surveyID) {
      isSegmantaLoaded(async () => {
        const status = await isSurveyCompleted(surveyID)
        setSurveyCompleted(status)
      })
    }
  }, [])

  return (
    <Button
      to={surveyID === '' && link}
      role='link'
      className={behaviour === 'Just_Card' ? style.cardWrapper : style.wrapper}
      handler={() => {
        if (behaviour === 'Take_Survey') {
          if (surveyID && !surveyCompleted) feedbackSurvey(surveyID)
          else window.location = '/survey'
        } else if (behaviour === 'Carousel') {
          carousalButtonClickEvent(window.location.origin + link)
        }
      }}
      aria-label={image.altText || text}
    >

      {image && (
        <div className={style.image}>
          {image && (
          <Image
            className={style.iconImage}
            {...image}
            altText={image.altText || text}
          />
          )}
        </div>
      )}
      <div className='hidden bg-warning text-white absolute bottom-0'>
        {offerLabelInFeaturedCard}
      </div>

      <div className={style.sweepWrapper}>
        {title && (
        <div className={style.sweepTitle}>
          {title && <Typography markdown={title} />}
        </div>
        )}
        <div className={style.sweepSubTitle}>
          { subTitle && (
          <p>
            {subTitle}
            { text && <u className={style.sweepUnderlineText}>{text}</u>}
            {iconName && pageSource !== 'rewardsDashboard' && (
            <span className='letGetStartedArrow inline-block pl-05'>
              {iconName && <Icon name={iconName} />}
            </span>
            )}
          </p>
          )}
          <div className='pt-24 pb-25 md:pt-20 text-center md:text-left'>
            <LinkButton
              {...callToActionsCollection?.items[0]}
              role='article'
              pageSource={pageSource}
              variant='Red'
              className='w-auto md:w-238'
            />
          </div>
        </div>
      </div>
      <style jsx global>
        {sweepstakeStyles}
      </style>
    </Button>
  )
}

SweepstakesCallout.propTypes = {
  image: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  link: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  variant: PropTypes.string,
  pageSource: PropTypes.string,
  surveyName: PropTypes.string.isRequired,
  behaviour: PropTypes.string,
  offerLabelInFeaturedCard: PropTypes.string,
  callToActionsCollection: PropTypes.oneOfType([PropTypes.object]),
}

SweepstakesCallout.defaultProps = {
  image: null,
  text: null,
  link: null,
  iconName: null,
  title: null,
  subTitle: null,
  variant: null,
  pageSource: null,
  behaviour: '',
  offerLabelInFeaturedCard: null,
  callToActionsCollection: null,
}

export default SweepstakesCallout
