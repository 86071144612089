import {
  rewardsDashboard, coupons,
} from '../../../constants/page-source'

module.exports = {
  default: {
  },

  [rewardsDashboard]: {
    default: {
    },
    sweepsstakes: {
      wrapper: 'sweep-rewards-tile relative shadow-articalmd bg-white items-center md:flex md:ml-0 mb-10px md:mr-0px lg:min-h-200 w-full lg:w-full overflow-hidden lg:mr-0 md:border-t-1 lg:border-t-2 md:border-redRewards',
      cardWrapper: 'pointer-events-none sweep-rewards-tile shadow-articalmd bg-white items-center md:flex md:ml-13px mb-10px md:mr-0px md:w-11/12 lg:w-full lg:mr-25px md:border-t-1 lg:border-t-2 md:border-redRewards',
      image: 'sweep-rewards-img border-t-onehalf md:border-t-0 border-redRewards md:flex-shrink-0 md:w-4/12 lg:w-5/12',
      sweepWrapper: 'sweepWrapper block text-left pt-15 px-20 md:px-20 lg:pr-30 pb-0 lg:pl-40 md:pt-20 xl:pt-30 md:pb-0',
      text: 'sweep-rewards-text ',
      sweepTitle: 'sweepTitleRewards font-montserratBold font-bold text-16 md:text-14 md:leading-seller18 xl:text-16 tracking-onethree text-redRewards pb-08 xl:pr-60 leading-lightmedium',
      sweepSubTitle: 'sweepSubTitleRewards text-14 md:text-12 md:leading-points16 xl:text-16 xl:leading-lightmedium font-montserratSemiBold font-semibold leading-seller18',
      sweepUnderlineText: 'sweepUnderlineText pl-05',
      icon: 'sweep-rewards-icon',
      iconImage: 'iconImage featuredRewardImg',
      completedBtn: 'tileDeqInactive sweepsRewardsInative text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
  [coupons]: {
    default: {
      wrapper: 'sweep-coupons-tile relative lg:w-wrapper97 mt-10px lg:ml-15px shadow-articalmd bg-white items-center md:flex md:ml-0px md:mr-0px md:w-full md:border-t-1 lg:border-t-2 md:border-secondary lg:mr-25px',
      cardWrapper: 'pointer-events-none sweep-coupons-tile lg:w-wrapper97 mt-10px lg:ml-15px shadow-articalmd bg-white items-center md:flex md:ml-0px md:mr-0px md:w-full md:border-t-1 lg:border-t-2 md:border-secondary lg:mr-25px',
      image: 'sweep-coupons-img hidden md:block mt-0 md:mt-0 border-t-onehalf md:border-t-0 border-secondary md:flex-shrink-0 md:w-4/12  lg:min-w-250',
      sweepWrapper: 'sweepWrapper  bg-white text-left pt-20 px-17 md:pr-20 pb-25 md:pb-0 md:pt-0 lg:pr-30 md:pl-40 lg:pl-70  md:w-7/12 lg:w-10/12  ',
      sweepTitle: 'sweepTitleCoupons font-montserratBold font-bold text-8 lg:text-sm lg:leading-seller18 text-secondary tracking-quater pb-05 leading-seller18',
      sweepSubTitle: 'sweepSubTitleCoupons  text-xs lg:text-lg lg:leading-lightmedium font-montserratSemiBold font-semibold leading-points16',
      sweepUnderlineText: 'sweepUnderlineText pl-05',
      text: 'sweep-coupons-text',
      icon: 'sweep-coupons-icon',
      iconImage: 'iconImageCoupons sweep-coupons-img  lg:w-w280 lg:h-190',
      completedBtn: 'tileDeqInactive sweepsRewardsInative text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
}
