import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Sellerstore from '../SellerLising/Seller.store'

function NextArrow(props) {
  const {
    className,
    onClick,
    children,
    currentSlide,
  } = props
  Sellerstore.showAnimation = currentSlide

  return (
    <button
      type='button'
      className={className}
      aria-label='Next Slide'
      onClick={onClick}
    >
      {children}
    </button>
  )
}

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  currentSlide: PropTypes.number,
}

NextArrow.defaultProps = {
  className: '',
  currentSlide: 0,
  onClick: () => {},
  children: (
    <img
      src='https://images.ctfassets.net/5tx4oa3foqys/105QjKUfz6AHURgwFBBDiT/0008fb90b19e0ff890cec191e2741dbe/next.svg'
      alt='next'
    />
  ),
}

export default observer(NextArrow)
